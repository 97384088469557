<template>
  <div style="overflow:hidden">
    <TopNavBar></TopNavBar>
    <TopNavBar2 id="navbar2" class="tnb2none"></TopNavBar2>
    <block1></block1>
    <block2></block2>
    <block3></block3>
    <block4></block4>
    <block5></block5>
    <block6></block6>
    <block67></block67>
    <block7></block7>
    <block8></block8>
    <form1></form1>
    <Footer1></Footer1>
  </div>
</template>
<script>
import TopNavBar from '../components/TopNavBar.vue'
import TopNavBar2 from '../components/TopNavBar2.vue'
import block1 from '../components/block1.vue'
import block2 from '../components/block2.vue'
import block3 from '../components/block3.vue'
import block4 from '../components/block4.vue'
import block5 from '../components/block5.vue'
import block6 from '../components/block6.vue'
import block67 from '../components/block6-7.vue'
import block7 from '../components/block7.vue'
import block8 from '../components/block8.vue'
import form1 from '../components/form.vue'
import Footer1 from '../components/footer.vue'
export default {
  metaInfo() {
    return {
      title: this.MetaData['title'], // set a title
      titleTemplate: this.MetaData['titleTemplate'], // %s  required
      htmlAttrs: this.MetaData['htmlAttrs'],
      meta: this.MetaData['meta'],
      link: [
        { rel: 'canonical', href: 'https://pennlogisticsllc.com/' }
      ]
    }
  },
  data() {
    return {
      metaData: {
        en: {
          Homepage: {
            title: 'Competent Freight Transportation Services | Pennsylvania Logistics', // set a title
            titleTemplate: '', // %s  required
            htmlAttrs: {
              lang: "en",
              amp: undefined // "amp" has no value
            },
            meta: [
              {
                'name': 'keywords',
                'content': 'freight transportation services, ftl shipping, ltl shipping, trucking, truckload, transportation company',
              },
              {
                'name': 'description',
                'content': 'Pennsylvania Logistics is a full-service freight transportation company. We deliver freights anywhere in the USA, Canada, Alaska, Mexico. Always on time.',
              },
              {
                property: 'og:url',
                content: 'https://pennlogisticsllc.com/',
                 vmid: 'og:url'
              },
              {
                property: 'og:title',
                content: 'Competent Freight Transportation Services | Pennsylvania Logistics',
                vmid: 'og:title'
              },
              {
                property: 'og:description',
                content: 'Pennsylvania Logistics is a full-service freight transportation company. We deliver freights anywhere in the USA, Canada, Alaska, Mexico. Always on time.',
                vmid: 'og:description'
              },
              {
                property: 'og:type',
                content: 'website',
                vmid: 'og:type'
              },
              {
                property: 'og:image',
                content: '../img/fon-block1.jpg',
                vmid: 'og:image'
              },
            ],
            link: [
              { rel: 'canonical', href: 'https://pennlogisticsllc.com/' }
            ]
          },
        },
      }
    }
  },
  name: 'Home',
  components: {
    TopNavBar, block1, block2, block3, block4, block5, block6, block7, block8, form1, Footer1, TopNavBar2, block67
  },
  mounted() {
    this.anim()
  },
  computed:{
    MetaData() {
      return this.metaData['en']['Homepage']
    }
  }
}
</script>
